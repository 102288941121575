import {db} from "../firebase.config"
import { useState, useEffect } from "react"
import { collection, onSnapshot } from "firebase/firestore"
import './Recipes.css'
import Recipe from  '../components/Recipe'
import {Link} from 'react-router-dom'


const Recipes = () =>{
    const [recipes,setRecipes] = useState([])
    const [form, setForm] = useState({
        title: "",
        desc: "",
        Ingredients: [],
        steps: [],
        image:""
    })

    const [popupActive, setpopupActive] = useState(false)
    const recipesCollectionRef = collection(db, "recipes")
    const [error, setError] = useState(false)
    const [data, setData] = useState(null)

    const images = require.context('../../public/images/recipes', true);
    // let dynamicImage = images(`./${data.image}.png`);
    
    useEffect (() => {
        onSnapshot(recipesCollectionRef, snapshot => {
            // if(snapshot.empty){
            //     setError('No recipes to load')
            // }
            // else{
                setRecipes(snapshot.docs.map(doc=> {
                    return {
                        id: doc.id,
                        viewing: false,
                        ...doc.data()
                    }
                }))
                setData(recipes)
                // console.log(recipes)
            // }
        })
    }, [])


    return <div>
        <div className="RecipesPage">
            <br/><br/>
            <h2> Delicious Recipes </h2>
            <h3> Take a look at some delicious recepies incorporating avocados below!</h3>
            <div className="recipe-list">
                {recipes.map(recipe =>  (
                    <div key={recipe.id} className="card">
                        <img src={images(`./${recipe.image}.png`)}  alt=""></img>
                        <h3>{recipe.title}</h3>
                        <p>{recipe.desc}</p>
                        <Link to={`/Recipe/${recipe.id}`} 
                           state= {{Recipe: recipe }}
                        className="linkBttn"> Cook This </Link>
                    </div>               
                ))}
        </div>
        </div>
    </div>
}

export default Recipes