import InfoSection from "../components/InfoSection"
import {aboutInfoData, aboutInfoDataTwo} from "../data/infoData"
import bannerImage from '../images/avocadoBanner.png'
import avocadoBasketThin from '../images/avocadoBasketThin.png'
import avocadoTreeThin from '../images/avocadoTreeThin.png'
import avocadoWood from '../images/avocadoWood.png'
import './AboutUs.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react"


const AboutUs = () =>
{
    useEffect(() => {
        Aos.init({})
    })

    return <div>
        <div className="bannerTop"> 
            <img src={bannerImage} alt="AvocadoLove"></img>
        </div>

        <div className="Container">
            <div className="row ">
                <div className="col-lg-4 align-self-center ">
                <h2 data-aos='fade-down' data-aos-diration='600'  >Our Story</h2>
                <p data-aos='fade-down' data-aos-diration='600' data-aos-delay='200' >  AVOCADOLOVE in McAllen, TX imports Mexican-grown avocado to the U.S.A., Canada, China, and other countries worldwide. Our clientele includes chain stores, wholesalers, and food service businesses.   </p>
                <p data-aos='fade-down' data-aos-diration='600' >We started our company to give growers in Mexico the opportunity to market their products on a global scale. Currently, we are handling sales for Mexico-based growers, and we look forward to working with more Mexican avocado growers in the near future.   </p>
                </div>
                <div data-aos='fade-down' data-aos-diration='600' data-aos-delay='200'  className="col-lg-4  img1 ">
                    <img src={avocadoBasketThin} alt="avocado basket"></img>
                </div>
                <div data-aos='fade-up' data-aos-diration='600'  data-aos-delay='200' className="col-lg-4 img2">
                    <img src={avocadoTreeThin} alt="avocado tree"></img>
                </div>
            </div>
        </div>

        <div className="Container">
            <div className="row">
                <div data-aos='fade-right' data-aos-diration='600'  className="col-lg-4  img3">
                    <img src={avocadoWood} alt="AvocadoLove"></img>
                </div>
                <div className="col-lg-4 ">
                    <h2 data-aos='fade-left' data-aos-diration='600'  >Why us? </h2>
                    <p data-aos='fade-left' data-aos-diration='600' data-aos-delay='200' >When you turn to us, you can be confident that you will be working with a reputable avocado importer. We have been part of the importing and exporting industries for more than 30 years. </p>
                    <p data-aos='fade-left' data-aos-diration='600' data-aos-delay='200' >In addition, we have been importing avocados for more than 10 years. Aside from our extensive experience, what sets us apart from the competition is that our customer service is second to none. Our priority is to guarantee your satisfaction and ensure that you and your clients are happy with our avocados.</p>
                </div>
            </div>
        </div>

        <div data-aos='fade-down' data-aos-diration='600' className="Container-White">
            <div className="containerText">
                <p>
                    Let us help you - 
                </p>
                <br/>
                <h3>
                    At Avocado Love we are always looking to help fulfill one's avocado needs. 
                    <a href="/ContactUs">Contact us today → </a>
                </h3>
            </div>
        </div>
{/* 
        <InfoSection{...aboutInfoData}/>
        <InfoSection{...aboutInfoDataTwo}/> */}

    </div>
}

export default AboutUs