import './SingleCard.css'
import backOfCard from '../images/matchingGame/BackofCard.png'
// import styled from "styled-components";


export default function SingleCard( { card, handleChoice,flipped,disabled }){

    const handleClick = () => {
        if(!disabled){
            handleChoice(card)
        }
    }
    return(
        <div>
            <div className="card" >
                <div className={flipped ? "flipped" : ""}>
                    <div>
                    <img className="front" src={card.src} alt="card front"/>
                    <img 
                        className="back" 
                        src={backOfCard} 
                        onClick={handleClick}
                        alt="back of card" />
                   </div>
                </div>
            </div>
        </div>
    )
}

// const CardContainer = styled.singlecard`
// .card{
//     position:relative;
// }

// .card img{
//     width: 100%;
//     display: block;
//     border: 2px solid #fff;
//     border-radius: 6px;
// }

// .card .front{
//     transform: rotateY(90deg);
//     transition: all ease-in 0.2s;
//     position: absolute;
// }

// .flipped .front{
//     transform: rotateY(0deg);
//     transition-delay: 0.2s;
// }

// .card .back{
//     transition: all ease-in 0.2s;
//     transition-delay: 0.2s;
// }

// .flipped .back{
//     transform: rotateY(90deg);
//     transition-delay: 0s;
// }
// `
