import "./Recipe.css"
import {useLocation } from "react-router-dom"
import React from "react"

export default function Recipe () {

    const location = useLocation()
    const data = location.state['Recipe'] 
    
    const images = require.context('../../public/images/recipes', true);
    let dynamicImage = images(`./${data.image}.png`);

 

    return(
        <div className="Content">
            <div className="row"  data-aos='fade-down' data-aos-diration='600'>
                <div className="col-lg">
                    <img src={dynamicImage} alt="Let's get cooking!"></img>
                </div>
                <div className="col-xl">
                    <h2>
                        {data.title}
                    </h2>
                    <p>
                        {data.desc}
                    </p>
                </div>
            </div>
            <div className="ingredientsSection">
                <h2  data-aos='fade-down' data-aos-diration='600'>
                    Ingredients
                </h2>
                <ul  data-aos='fade-down' data-aos-diration='200'>
                {data.Ingredients.map((ingredient,index) => <li key={`ingredient-${index}`}>{ingredient}</li>)}
                </ul>
            </div>
            
            <div className="instructionsSection">
                <h2  data-aos='fade-down' data-aos-diration='600'>
                    Instructions
                </h2>
                <ol  data-aos='fade-down' data-aos-diration='200'>
                    {data.steps.map((step,index) => <li key={`step-${index}`}>{step}</li>)}
                </ol>
            </div>
            
       </div>

    )
}