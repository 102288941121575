import './AllAboutAvocados.css'
import SingleCard from '../components/SingleCard'
import {useEffect, useState} from 'react'
import avocadoCyan from '../images/matchingGame/avocadoCyan.png'
import avocadoGreen from '../images/matchingGame/avocadoGreen.png'
import avocadoOrange from '../images/matchingGame/avocadoOrange.png'
import avocadoPeach from '../images/matchingGame/avocadoPeach.png'
import avocadoPink from '../images/matchingGame/avocadoPink.png'
import avocadoPurple from '../images/matchingGame/avocadoPurple.png'
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const cardImages = [
    { "src": avocadoCyan, matched: false},
    { "src": avocadoGreen, matched: false},
    { "src": avocadoOrange, matched: false},
    { "src": avocadoPeach, matched: false},
    { "src": avocadoPink, matched: false},
    { "src": avocadoPurple, matched: false}
]


const AllAboutAvocados = () =>
{
    const [isOpen, setIsOpen] = useState(false);
    const [cards, setCards] = useState([])
    const [turns, setTurns] = useState(0)
    const [choiceOne, setChoiceOne] = useState(null)
    const [choiceTwo, setChoiceTwo] = useState(null)
    const [disabled, setDisabled] = useState(false)

    const showModal = () => {
        setIsOpen(true);
      };
    
      const hideModal = () => {
        setIsOpen(false);
      };
    
    // Shuffle Cards
    const shuffleCards = () =>{
        const shuffleCards = [...cardImages, ...cardImages]
        .sort(() => Math.random() - 0.5)
        .map((card) => ({...card, id:Math.random()}))

        setChoiceOne(null)
        setChoiceTwo(null)
        setCards(shuffleCards)
        setTurns(0)
    }

    // handle a choice
    const handleChoice = (card) => {
        choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
    }    

    //compare the two selected cards
    useEffect(() => {
        if(choiceOne && choiceTwo){
            setDisabled(true)

            if(choiceOne.src === choiceTwo.src){
                setCards(prevCards => {
                    return prevCards.map(card => {
                        if(card.src === choiceOne.src){
                            return{...card, matched: true}
                        }else{
                            return card
                        }
                    })
                })
                resetTurn()
            } else {
                setTimeout(() => resetTurn(), 1000)
            }
        }
    }, [choiceOne, choiceTwo])

    console.log(cards)

    //reset choices and increase turn count
    const resetTurn=() =>{
        setChoiceOne(null)
        setChoiceTwo(null)
        setTurns(prevTurns => prevTurns + 1)
        setDisabled(false)
    }

    //Start a new game automatically
    useEffect(() => {
        shuffleCards() 
    }, [])

    return (
        <div className="content">
            <h1>Avocado Matching</h1>

            <div className="card-grid">
                {cards.map(card => (
                    <SingleCard  
                        key={card.id} 
                        card={card}
                        handleChoice={handleChoice}
                        flipped={card === choiceOne || card===choiceTwo || card.matched}
                        disabled={disabled}
                    />
                ))}
            </div>
            {/* add grid */}
            <div className="buttonArea">
                <div className="container">
                <div className="row">
                {/*column 1*/}
                    <div className="col-lg-4">
                    <h2>
                        Turns: {turns}
                    </h2>
                    </div>
                    <div className="col-lg-4">
                    <button onClick={shuffleCards}> Restart Game </button>
                    </div>
                    <div className="col-lg-4">
                    <button onClick={showModal}>Help</button>
                    <Modal show={isOpen} onHide={hideModal} dialogClassName={"primaryModal"}>
                        <Modal.Header>
                        <Modal.Title>How to play</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Click on each card to reveal a fun avocado, click on a second card to try and find its matching pair. 
                            If it is match, the cards will stay otherwise the cards will flip and you must take another guess. 
                            Win the game by finding all matching cards. The number of turns taken will be recorded below the cards.
                            <br/><br/>To restart you can click on the restart game button and the turns count will reset. 
                            <br/><br/> Happy Playing!
                        </Modal.Body>
                        <button onClick={hideModal}>Close</button>
                    </Modal>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
    
}

export default AllAboutAvocados