import React from "react";
import styled from "styled-components";
import * as AiIcons from "react-icons/ai"
import companyLogo from '../images/logoPink.jpeg';


function Footer(){
    return (
        <FooterContainer className="main-footer">
            <div className="footer-middle">
            <div className="container">
                <div className="row">
                    {/*column 1*/}
                    <div className="col-sm-4 ">
                        <img src={companyLogo} alt="Avocado Love"></img>
                    </div>
                    {/*column 2*/}
                    <div className="col-sm-4">
                        <h3>Learn More</h3>
                        <ul className="list-unstyled">
                            <li> 
                                <a href="/AboutUs">About Us </a>
                            </li>
                            <li> 
                                <a href="/ContactUs">Contact Us </a>
                            </li>
                        </ul>
                    </div>
                    {/*column 3*/}
                    <div className="col-sm-4 ">
                        <br/>
                        <ul className="list-unstyled">
                            <li> 
                                <a href="tel:+19564412154"><AiIcons.AiFillPhone /> (956)441-2154 </a>
                            </li>
                            <li> 
                                <a href="mailto: jeff@avocado.love"><AiIcons.AiOutlineMail /> jeff@avocado.love </a>
                            </li>
                        </ul>
                    </div>
                </div>
            {/* Footer Bottom */}
            <div className="footer-bottom">
                <p className="text-xs-center">
                    &copy;{new Date().getFullYear()} AvocadoLove - All Rights Reserved
                </p>
            </div>
            </div>
            </div>
        </FooterContainer>
    );
}
export default Footer

const FooterContainer = styled.footer`
.footer-middle {
    background: var(--mainDarkGreen);
    padding-top: 2rem;
    color: var(--mainWhite);
}

.footer-bottom{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

ul li {
    font-size:large;
}

ul li a {
    color: var(--mainWhite);
    text-decoration:none;
}

 ul li a:hover {
     color: var(--mainGrey);
 }

 img {
     width: 80%;
 }

`;