import avocadoLeft from '../images/avocadoLeft.png'
import avocadoRight from '../images/avocadoRight.png'
import avocadoLoveLogo from '../images/logoOutline.png'
import avocadoToast from '../images/avocadoToast.png'
import avocadoTree from '../images/avocadoTree.png'
import contactPic from '../images/contactPic.png'
import avocadoBowl from '../images/avocadoBowl.png'
import './home.css'
import React,{useEffect, useState} from "react"
import Aos from 'aos'
import 'aos/dist/aos.css'

const Home = () =>
{
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        Aos.init({})
    })
    const handleScroll = () => {
        setOffset(window.scrollY);
      };
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);


    return (
    <div className="home">
        {/* Top of Zoom */}
        <div className="zoom">
            <img src = {avocadoLeft} alt="Avocado Left" id="avocadoLeft"
                style={{width:(90 + offset * 0.4) + '%'}}
            />
            <img src = {avocadoRight} alt="Avocado Right" id="avocadoRight" 
                style={{width:(90 + offset * 0.3) + '%'}}

            />
            <img src = {avocadoLoveLogo} alt="Avocado Love" id="avocadoLoveLogo" 
                // style = {{width: (90 +offset * 0.3) + '%'}}
            />
        </div>


        {/* Image Links */}
        <div className="content-links">
        <br/>
        <h2 data-aos='fade-down' data-aos-diration='600'> Trusted Avocado Importer & Exporter </h2>
        <h3 data-aos='fade-down' data-aos-diration='600'> Your one stop shop for avocados  <br/></h3>                 

        <div className="imgLinks">
            <div className="row">
                {/*column 1*/}
                <div className="col-md-4">
                        <a href="/recipes">
                            <img data-aos='fade-down' 
                            data-aos-diration='600' 
                            data-aos-delay='200'
                            src={avocadoToast} 
                            alt="Recipes"/>
                        </a>
                    <a href="/Recipes"data-aos='fade-down' data-aos-diration='600' ><br/><br/>Recipes</a>   
                </div>

                {/*column 2*/}
                <div className="col-md-4">
                        <a href="/AboutUs">
                        <img data-aos='fade-down' 
                        data-aos-diration='600' 
                        data-aos-delay='200' 
                        src={avocadoTree} 
                        alt="About Us"/>
                        </a>
                    {/* <a href="/AboutUs">About Us </a> */}
                    <a href="/AboutUs" data-aos='fade-down' data-aos-diration='600' ><br/><br/>About Us</a>  
                </div>

                {/*column 3*/}
                <div className="col-md-4">
                    <a href="/ContactUs">
                    <img 
                        src={contactPic} 
                        alt="Contact Us"
                        data-aos='fade-down' 
                        data-aos-duration='600' 
                        data-aos-delay='200'
                    />
                    </a>
                    <a href="/ContactUs" data-aos='fade-down' data-aos-duration='600' ><br/><br/>Contact Us</a>  
                </div>
            </div>
        </div>
        </div>



        <div className="avocadoNutrition">
            <div className="row">
                <div className="col-md-5">
                <img data-aos="fade-right" data-aos-duration='600'  src={avocadoBowl} alt="Avocado"/>
                </div>
                <div className="col-md-7" >
                    <h2 data-aos="fade-down" data-aos-duration='600' >Nutrition</h2>
                <ul>
                    <li data-aos="fade-down" data-aos-duration='600' data-aos-delay='350'>Nutritionally rich 
                        <br/>(Vitamin A, 6B, C, E, Potassium, Magnesium, Iron)
                    </li>
                    <li data-aos="fade-down" data-aos-duration='600' data-aos-delay='350'>Supports heart health</li>
                    <li data-aos="fade-down" data-aos-duration='600' data-aos-delay='350'>Lowers cholesterol</li>
                    <li data-aos="fade-down" data-aos-duration='600' data-aos-delay='350'>Helps regulate appetite </li>
                    <li data-aos="fade-down" data-aos-duration='600' data-aos-delay='350'>Helps keep eyes healthy</li>
                    <li data-aos="fade-down" data-aos-duration='600' data-aos-delay='350'>Aids in skin health</li>
                </ul>
                </div>
            </div>
        </div>

 
        <div className="healthyHappy">
            {/* avocado 1 */}
            <div className="row">
                <div className="col-sm-3">
                <img data-aos="fade-right" data-aos-duration='600'  src={avocadoRight} alt="Avocado"/>
                <br/><br/><br/><br/><br/>
                </div>
                <div className="col-sm-6" ></div>
                <div className="col-sm-3" ></div>
            </div>
            {/* slogan */}
            <div className="row">
                    <h2>Healthy Means Happy</h2>
            </div>
            {/* avocado 2 */}
            <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6" ></div>
                <div className="col-sm-3" >
                <img data-aos="fade-left" data-aos-duration='600'  src={avocadoLeft} alt="Avocado"/>
                </div>
            </div>
        </div>
    </div>
    
    )


}

export default Home