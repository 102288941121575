// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar'
import {BrowserRouter as Router,Route, Routes} from 'react-router-dom'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import AllAboutAvocados from './pages/AllAboutAvocados'
import ContactUs from './pages/ContactUs'
import Recipes from './pages/Recipes'
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './globalStyles';
import {useState} from 'react';
import Dropdown  from './components/Dropdown';
import ScrollToTop from './pages/ScrollToTop';
import Recipe from './components/Recipe';

function App() {
  // const  [showNav, setShowNav ] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen);
  }
  return (
    <>
    <div className = 'body'>
      <GlobalStyle/>
      <Router >
      <ScrollToTop/>
      <Navbar toggle={toggle}/>
      <Dropdown isOpen={isOpen} toggle={toggle} />
        <Routes >
          <Route path='/' exact={true} element={<Home/>}/>
          <Route path='/AboutUs' exact={true} element={<AboutUs/>}/>
          <Route path='/AllAboutAvocados' exact={true} element={<AllAboutAvocados/>}/>
          <Route path='/ContactUs' exact={true} element={<ContactUs/>}/>
          <Route path='/Recipes' exact={true} element={<Recipes/>}/>
          <Route path='/Recipe/:id' element={<Recipe />}></Route>
        </Routes>

        <Footer></Footer>
      </Router>
      </div>
    </>
  )
}

export default App

