import './ContactUs.css'
import * as AiIcons from "react-icons/ai"
import * as HiIcons from "react-icons/hi"
import { useState, useEffect,useRef } from "react"
import Aos from 'aos'
import 'aos/dist/aos.css'
import emailjs from "emailjs-com"




const ContactUs = () =>
{
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: "",
    });

    const[submitted, setSubmitted] = useState(false);
    const[valid, setValid] = useState(false);

    const handleFirstNameInputChange = (event) => {
        setValues({...values,firstName:event.target.value})
    }

    const handleLasttNameInputChange = (event) => {
        setValues({...values,lastName:event.target.value})
    }

    const handleEmailInputChange = (event) => {
        setValues({...values,email:event.target.value})
    }

    const handleMessageInputChange = (event) => {
        setValues({...values,message:event.target.value})
    }
    const handleSubjectInputChange = (event) => {
        setValues({...values,subject:event.target.value})
    }
    
    const form = useRef();

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if(values.firstName && values.lastName && values.email &&values.message && values.subject){
    //         setValid(true);
    //     }
    //     setSubmitted(true);
    // }

    const sendEmail = (e) => {
        e.preventDefault();

        if(values.firstName && values.lastName && values.email &&values.message && values.subject){
            setValid(true);
            emailjs.sendForm('service_pxetaiw', 'template_gvlkgjq', form.current, 'L9NpjLuoeW8OXKaMN')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset()
        }
        setSubmitted(true);
        setValues({
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
        });

    }


    useEffect(() => {
        Aos.init({})
    })

    return <div>
        <div>
            <div className="section">
                <div className="heading">
                    <h2>Contact Us</h2>
                    <h3>Questions, comments, or compliments? <br/> Fill out the form and we will get in touch! </h3>
                </div>
                <div className="contact" data-aos='fade-down' data-aos-diration='900' data-aos-delay='500'>
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                        <div className="form-container">
                        <form className="register-form" ref={form} onSubmit={sendEmail} >
                            {/*  next line to show the success message */}
                            {submitted && valid ? <div className="success-message">Success! Thank you for submitting, someone will get back to you soon!</div> : null}
                            <input
                                onChange={handleFirstNameInputChange}
                                value={values.firstName}
                                className="form-field"
                                // type="text"
                                placeholder="First Name"
                                name="firstName"
                            />
                            {submitted && !valid && !values.firstName ? <span id="first-name-error">Please enter a first name</span> : null}
                            <input
                                onChange={handleLasttNameInputChange}
                                value={values.lastName}
                                className="form-field"
                            //   type="text"
                                placeholder="Last Name"
                                name="lastName"
                            />
                            {submitted && !valid  && !values.lastName ? <span id="last-name-error">Please enter a last name</span> : null}
                            <input
                              onChange={handleEmailInputChange}
                              value={values.email}
                              className="form-field"
                            //   type="text"
                              placeholder="Email"
                              name="email"
                            />
                            {submitted && !valid  && !values.email ? <span id="email-error">Please enter an email address</span> : null}
                            <input
                                onChange={handleSubjectInputChange}
                                value={values.subject}
                                className="form-field"
                                // type="text"
                                placeholder="Subject"
                                name="subject"
                            />
                            {submitted && !valid  && !values.subject ? <span id="subject-name-error">Please select a subject</span> : null}
                            <textarea
                                onChange={handleMessageInputChange}
                                value={values.message}
                                className="form-field"
                                // type="text"
                                placeholder="Message"
                                name="message"
                            />
                            {submitted && !valid  && !values.message ? <span id="first-name-error">Please enter a message name</span> : null}
                            <button className="form-field" type="submit">
                              Submit
                            </button>
                        </form>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 align-self-center">
                        <div className="ContactInfo" >
                            <div className = "box-info">
                                <div className="icon">
                                <AiIcons.AiOutlinePhone  />
                                </div>
                                <div className ="text">
                                <h3> Phone </h3>
                                {/* <p> (956)441-2154</p> */}
                                <a href="tel:+19564412154">(956)441-2154 </a>
                                </div>
                            </div>
                            <div className = "box-info">
                                <div className="icon">
                                <AiIcons.AiOutlineMail  />
                                </div>
                                <div className = "text">
                                    <h3> Email </h3>
                                <a href="mailto: jeff@avocado.love"> jeff@avocado.love</a>
                                </div>
                            </div>
                            <div className = "box-info">
                                <div className="icon"> 
                                <HiIcons.HiOutlineLocationMarker/>
                                </div>
                                <div className = "text">
                                    <h3> Address </h3>
                                    {/* <p> 4900 W Expressway 83, Suite 250 McAllen, TX 78501</p> */}
                                <a href="http://maps.google.com/?q=4900 W Expressway 83, Suite 250 McAllen, TX 78501"> 4900 W Expressway 83, Suite 250 McAllen, TX 78501 </a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section" data-aos='fade-down' data-aos-diration='900' data-aos-delay='200'>
                <h2>Our Location </h2>  
                <div className="map" data-aos='fade-down' data-aos-diration='900' data-aos-delay='500'>
                    <iframe 
                    title="AvocadoLoveMap"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14319.941576745017!2d-98.2772502!3d26.1971534!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbc81f529da8f641d!2sAvocado%20Love%20Marketing!5e0!3m2!1sen!2sus!4v1647125523738!5m2!1sen!2sus" 
                    align="center"
                    style={{ border: "0" }}
                    loading="lazy">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
}

export default ContactUs