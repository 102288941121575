import React, {useState, useEffect} from "react"
import { Link, useLocation } from "react-router-dom"
import * as FaIcons from "react-icons/fa"
import styled, {css} from "styled-components/macro"
import { menuData } from "../data/menuData"

const Nav = styled.nav`
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    z-index:1001;
    position: fixed;
    width: 100%;
`;

const NavLink = css`
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%
    cursor: pointer;
    text-decoration: none;
`

const Logo = styled(Link)`
    ${NavLink}
    font-style: italic;
`;
const MenuBars = styled(FaIcons.FaBars)`
    display: none;

    @media screen  and (max-width: 768px){
        display: block;
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%,25%);
        color: white;
    }
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;


    @media screen and (max-width: 768px){
        display: none; 

    }
`;

const NavMenuLinks = styled(Link)`
    ${NavLink}
`;



function Navbar({toggle}){
    const [navbar,setNavbar] = useState(false)
    const location = useLocation ()

    const changeBackground = () => {
        if(window.pageYOffset >= 60){
            setNavbar(true)
        } else{
            setNavbar(false)
        }
    }

    useEffect (() => {
        const watchScroll = () => {
            window.addEventListener('scroll',changeBackground)
        };

        watchScroll();

        return() => {
            window.removeEventListener('scroll', changeBackground);
        }
    },[]);

    let style = {
        backgroundColor: navbar || location.pathname !== "/" ?'#2b6337' : 'transparent',
        transition: '0.4s'
    }

    return(
        <Nav style={style}>
            <Logo to="/">Avocado Love</Logo>
            <MenuBars onClick={toggle}/>
            <NavMenu>
                {menuData.map((item, index) => (
                    <NavMenuLinks to={item.link} key={index}>
                        {item.title}
                    </NavMenuLinks>
                ))}
            </NavMenu>
        </Nav>
    )
}

export default Navbar