import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    box-sizing: border-box;
}

html,body{
    overflow-x: hidden;
}
`;

export default GlobalStyle