// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyChsLYkULlZ9Yn8VRzZm136lGOhR0K14ac",
  authDomain: "avocadolove-c7d2c.firebaseapp.com",
  projectId: "avocadolove-c7d2c",
  storageBucket: "avocadolove-c7d2c.appspot.com",
  messagingSenderId: "917493751559",
  appId: "1:917493751559:web:93dffc54f2d1704a66afa2",
  measurementId: "G-N6TWNT3BP6"
};

// Initialize Firebase, connect to firebase backend
const app = initializeApp(firebaseConfig);

//Initialize services
const db = getFirestore(app);

export { db }